import { render, staticRenderFns } from "./GameListSorter.vue?vue&type=template&id=86e6c630&scoped=true&"
import script from "./GameListSorter.vue?vue&type=script&lang=ts&"
export * from "./GameListSorter.vue?vue&type=script&lang=ts&"
import style0 from "./GameListSorter.vue?vue&type=style&index=0&id=86e6c630&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86e6c630",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
