








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Banner from "@/components/Banner.vue";

import { Game } from "@/store/modules/games";

@Component({
  components: {
    Banner,
  },
})
export default class GameList extends Vue {
  @Prop(Array) readonly games: Game[];
}
