












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Game } from "@/store/modules/games";

@Component
export default class GameListCounter extends Vue {
  @Prop(Array) games!: Game[];
}
