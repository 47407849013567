

























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

@Component
export default class GameListSorter extends Vue {
  @Prop(String) value!: string;

  selectItems = [
    { text: i18n.t("gamelist.sorts.title"), value: "title" },
    { text: i18n.t("gamelist.sorts.new"), value: "new" },
    { text: i18n.t("gamelist.sorts.popular"), value: "popular" },
  ];
}
